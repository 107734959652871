import {
  ActionButton,
  Button,
  Col,
  Container,
  FAB,
  FabSpeedDial,
  FormikInputInteger,
  Loading,
  Modal,
  Panel,
  Row,
  SectionTitle,
  Yup,
  useShowNotification
} from '@elotech/components';
import { Formik, FormikProps } from 'formik';
import { History } from 'history';
import { Alert } from 'iss-common/utils';
import React, { useEffect, useReducer, useState } from 'react';
import { match } from 'react-router-dom';
import styled from 'styled-components';

import { CadastroImobiliario } from '../../../common/components/ModalCadastroImobiliario';
import AtoProcessoService from '../../../service/AtoProcessoService';
import CadastroGeralService from '../../../service/CadastroGeralService';
import ProcessoService from '../../../service/ProcessoService';
import TipoLocalInternoService from '../../../service/TipoLocalInternoService';
import UsuarioService from '../../../service/UsuarioService';
import { AtoProcesso } from '../../../types/AtoProcesso';
import { Processo } from '../../../types/Processo';
import { Roles } from '../../../utils/Roles';
import { LocalField, TipoLocal } from '../LocalField';
import { AtosList } from './AtosList';
import { AtosNotFound } from './AtosNotFound';
import { AtosTimeline } from './AtosTimeLine';
import { NewAtoPage } from './NewAtoPage';
import { ProcessoDetail } from './ProcessoDetail';
import { ProcessoDetailDenunciante } from './ProcessoDetailDenunciante';
import { ProcessoDetailReu } from './ProcessoDetailReu';

type Props = {
  match: match<{ id: string }>;
  history: Pick<History, 'push'>;
  historypush: Pick<History, 'push'>;
};

const initialValues: Processo = {
  id: 0
};

export const ProcessoDetailPage: React.FC<Props> = ({
  match,
  history,
  historypush
}) => {
  const [{ showList, showTimeLine, showNewAto }, dispatch] = useReducer(
    (_: any, action: { type: string }) => ({
      showList: action.type === 'list',
      showTimeLine: action.type === 'timeline',
      showNewAto: action.type === 'newAto'
    }),
    { showList: true, showTimeLine: false, showNewAto: false }
  );

  const [loading, setLoading] = useState<boolean>(true);
  const [loadingAtosList, setLoadingAtosList] = useState<boolean>(false);
  const [atos, setAtos] = useState<AtoProcesso[]>();
  const [disableNovoAto, setDisableNovoAto] = useState<boolean>();
  const [motivoDisableNovoAto, setMotivoDisableNovoAto] = useState<string>();
  const [podeAlterarProtocolo, setPodeAlterarProtocolo] = useState(false);
  const [podeAlterarPrazoProcesso, setPodeAlterarPrazoProcesso] = useState(
    false
  );
  const [podeReabrirProcesso, setPodeReabrirProcesso] = useState(false);
  const [showModalNumeroProtocolo, setShowModalNumeroProtocolo] = useState(
    false
  );
  const [showModalNovoReu, setShowModalNovoReu] = useState(false);
  const [processo, setProcesso] = useState<Processo>(initialValues);
  const [tipoReu, setTipoReu] = useState<TipoLocal>('EXTERNO');
  const [showModal, setShowModal] = useState(false);
  const showNotification = useShowNotification();

  const handleShowModal = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    setLoading(true);

    ProcessoService.validarPermissaoProcesso(match.params.id)
      .then(() => {
        UsuarioService.permissoesByUsuario().then(permissoes => {
          setPodeAlterarProtocolo(
            !permissoes.data.clientRoles.some(
              x => x.name === Roles.fiscalizacao_alterar_numero_protocolo.name
            )
          );
          setPodeAlterarPrazoProcesso(
            !permissoes.data.clientRoles.some(
              x =>
                x.name ===
                Roles.fiscalizacao_alterar_dt_abertura_fechamento_proc.name
            )
          );
          setPodeReabrirProcesso(
            !permissoes.data.clientRoles.some(
              x => x.name === Roles.fis_supervisor.name
            )
          );

          ProcessoService.findById(match.params.id).then(processo => {
            setProcesso(processo.data);
          });
        });
      })
      .catch(error => {
        history.push(`/sem-permissao`);
      });

    setLoading(false);
  }, [match.params.id, history]);

  useEffect(() => {
    setLoadingAtosList(true);
    const { id } = match.params;
    AtoProcessoService.findAllByIdProcesso(id)
      .then(value => {
        verificarSeDesabilitaAto(id);
        setAtos(value.data);
      })
      .finally(() => setLoadingAtosList(false));
  }, [match.params, processo]);

  useEffect(() => {
    function handleEscapeKey(event: KeyboardEvent) {
      if (event.code === 'Escape') {
        setShowModal(false);
        setShowModalNovoReu(false);
      }
    }

    document.addEventListener('keydown', handleEscapeKey);
    return () => document.removeEventListener('keydown', handleEscapeKey);
  }, []);

  const openAto = (id: number, idProcesso: number) =>
    history.push(`/processo/${idProcesso}/processo-ato/${id}`);

  const verificarSeDesabilitaAto = (id: string) => {
    ProcessoService.findById(id).then(response => {
      setDisableNovoAto(
        response.data.status === 'FECHADO' ||
          response.data.status === 'CANCELADO'
      );

      if (
        response.data.status === 'EM_TRANSFERENCIA' &&
        response.data.tipoProcesso &&
        response.data.tipoProcesso.bloqueiaAtosProcessosTransferencia
      ) {
        setDisableNovoAto(true);
        setMotivoDisableNovoAto(
          'Para adicionar um novo ato é necessário receber o processo'
        );
      }

      if (
        response.data.tipoProcesso &&
        response.data.tipoProcesso.reuNaoObrigatorio &&
        !response.data.reu
      ) {
        setDisableNovoAto(true);
        setMotivoDisableNovoAto(
          'É necessário preencher o Réu para adicionar um novo ato.'
        );
      }
    });
  };

  const alterarNumeroProtocolo = async (processo: Processo, setValues: any) => {
    setLoading(true);
    let processoToSave = processo;
    processoToSave.numeroProtocolo = `${processo.numeroProtocolo}/${processo.anoProtocolo}`;
    ProcessoService.alterarNumeroProtocolo(processoToSave.id, {
      numeroProtocolo: processoToSave.numeroProtocolo
    })
      .then(() => {
        setValues({ ...processo });
        showNotification({
          level: 'success',
          message: 'Número do protocolo alterado com sucesso.'
        });
      })
      .catch(error => {
        Alert.error(
          {
            title: 'Não foi possível alterar o número de protocolo.'
          },
          error
        );
      })
      .finally(() => {
        setLoading(false);
        setShowModalNumeroProtocolo(false);
      });
  };

  const gravarNovoReu = async (reu: any) => {
    setLoading(true);
    ProcessoService.alterarReu(
      processo.id,
      tipoReu === 'EXTERNO' ? reu.reuExterno : reu.reuInterno,
      tipoReu === 'EXTERNO' ? 'externo' : 'interno'
    )
      .then(() => {
        showNotification({
          level: 'success',
          message: 'Réu adicionado com sucesso'
        });
        ProcessoService.findById(match.params.id).then(processo => {
          setProcesso(processo.data);
        });
      })
      .catch(error => {
        Alert.error(
          {
            title: 'Não foi possível adicionar o Réu.'
          },
          error
        );
      })
      .finally(() => {
        setLoading(false);
        setShowModalNovoReu(false);
      });
  };

  const getNumeroProtocoloInitialValue = (processo: Processo) => {
    let processoToEdit = JSON.parse(JSON.stringify(processo));
    processoToEdit.numeroProtocolo = undefined;
    processoToEdit.anoProtocolo = undefined;
    return processoToEdit;
  };
  return (
    <Container breadcrumb>
      <Formik
        enableReinitialize
        initialValues={processo}
        onSubmit={() => ''}
        render={({ values, setValues }: FormikProps<Processo>) => (
          <>
            <SectionTitle marginTop={'0'}>Dados do Processo</SectionTitle>
            <ProcessoDetail processo={values} />
            <SectionTitle>Dados do Contribuinte</SectionTitle>
            <ProcessoDetailReu processo={processo} />
            <SectionTitle>Dados do Denunciante</SectionTitle>
            <ProcessoDetailDenunciante processo={processo} />

            <SectionTitle>
              Atos
              <ButtonContainer>
                <ActionButton
                  icon="plus"
                  label={
                    disableNovoAto && motivoDisableNovoAto
                      ? motivoDisableNovoAto
                      : 'Novo ato'
                  }
                  onClick={() => {
                    AtoProcessoService.existeEncaminhamentoAbertoUsuario(
                      values.id
                    ).then(result => {
                      if (result.data) {
                        Alert.error({
                          title:
                            'É necessário fazer o recebimento do Processo antes de continuar'
                        });
                        return;
                      }
                      dispatch({ type: 'newAto' });
                    });
                  }}
                  disabled={disableNovoAto}
                />
                <ActionButton
                  customClass={`${showTimeLine && 'module-color'}`}
                  icon="chart-line"
                  label="Linha do tempo"
                  onClick={() => dispatch({ type: 'timeline' })}
                />
                <ActionButton
                  customClass={`${showList && 'module-color'}`}
                  icon="list"
                  label="Lista"
                  onClick={() => dispatch({ type: 'list' })}
                />
              </ButtonContainer>
            </SectionTitle>
            {showModalNumeroProtocolo && (
              <Modal onClose={() => setShowModalNumeroProtocolo(false)}>
                {
                  <Panel isForm title="Deseja alterar o número do protocolo?">
                    <Formik
                      enableReinitialize
                      initialValues={getNumeroProtocoloInitialValue(values)}
                      onSubmit={value =>
                        alterarNumeroProtocolo(value, setValues)
                      }
                      validationSchema={Yup.object().shape({
                        numeroProtocolo: Yup.string()
                          .required()
                          .label('Número do Protocolo'),
                        anoProtocolo: Yup.string()
                          .required()
                          .test('anoValorValido', 'Ano inválido', function(
                            value
                          ) {
                            if (value === undefined) {
                              return true;
                            }
                            return Number(value.length) === 4;
                          })
                          .label('Ano do Protocolo')
                      })}
                      render={(formProps: FormikProps<any>) => (
                        <>
                          <Row id={'alert'}>
                            <FormikInputInteger
                              label="Número do Protocolo"
                              name="numeroProtocolo"
                              size={3}
                              maxLength={8}
                              fast={false}
                            />
                            <FormikInputInteger
                              label="Ano do Protocolo"
                              name="anoProtocolo"
                              size={3}
                              maxLength={4}
                              fast={false}
                            />
                          </Row>
                          <Row>
                            <Col md={12} className="form-group">
                              <Button
                                onClick={formProps.submitForm}
                                color="positive"
                                className="inline mt-xs mb-xs"
                              >
                                Confirmar
                              </Button>
                              <Button
                                onClick={() =>
                                  setShowModalNumeroProtocolo(false)
                                }
                                color="neutral"
                                className="inline mt-xs mb-xs"
                              >
                                Cancelar
                              </Button>
                            </Col>
                          </Row>
                        </>
                      )}
                    ></Formik>
                  </Panel>
                }
              </Modal>
            )}
            {showModalNovoReu && (
              <Modal onClose={() => setShowModalNovoReu(false)}>
                {
                  <Panel isForm title="Vincular novo Réu ao processo">
                    <Formik
                      initialValues={{}}
                      enableReinitialize
                      onSubmit={value => gravarNovoReu(value)}
                      render={(formProps: FormikProps<any>) => (
                        <>
                          <Row>
                            <LocalField
                              namePrefix="reu"
                              labelPrefix="Réu"
                              openModal={handleShowModal}
                              permiteImobiliario={
                                processo.tipoProcesso?.exigeCadastroImobiliario
                              }
                              onSearchCadastroGeral={
                                CadastroGeralService.autoCompleteReuExterno
                              }
                              onSearchTipoLocal={TipoLocalInternoService.load}
                              size={4}
                              tipo={tipoReu}
                              onSetTipo={(tipo: any) => setTipoReu(tipo)}
                            />
                          </Row>
                          <Row>
                            <Col md={12} className="form-group">
                              <Button
                                onClick={formProps.submitForm}
                                color="positive"
                                className="inline mt-xs mb-xs"
                              >
                                Confirmar
                              </Button>
                              <Button
                                onClick={() => setShowModalNovoReu(false)}
                                color="neutral"
                                className="inline mt-xs mb-xs"
                              >
                                Cancelar
                              </Button>
                            </Col>
                          </Row>
                          {showModal && (
                            <CadastroImobiliario
                              onCloseModal={() => setShowModal(false)}
                              historypush={historypush}
                              formProps={formProps}
                            />
                          )}
                        </>
                      )}
                    ></Formik>
                  </Panel>
                }
              </Modal>
            )}

            <Loading loading={loading} />
            {!loadingAtosList && !atos?.length && !showNewAto && (
              <AtosNotFound
                onClickNovoAto={() => dispatch({ type: 'newAto' })}
                onClickNovoReu={() => setShowModalNovoReu(true)}
                disableNovoAto={disableNovoAto}
              />
            )}
            {!!atos?.length && showList && (
              <AtosList
                atos={atos}
                onViewClick={openAto}
                loading={loadingAtosList}
              />
            )}
            {!!atos?.length && showTimeLine && (
              <AtosTimeline onViewClick={openAto} atos={atos} />
            )}
            {showNewAto && (
              <NewAtoPage
                processo={values}
                atos={!!atos?.length ? atos : []}
                history={history}
              />
            )}
            <FabSpeedDial icon="ellipsis-v" title="Ações">
              <FAB
                data-testid="btn-num-protocolo-processo"
                icon="file-alt"
                disabled={podeReabrirProcesso && values.status !== 'ABERTO'}
                onClick={() => reabrirProcesso(values)}
                title="Reabrir Processo"
              />
              <FAB
                data-testid="btn-num-protocolo-processo"
                icon="file-signature"
                disabled={values.status !== 'EM_TRANSFERENCIA'}
                onClick={() => receberProcesso(values, setValues)}
                title="Receber processo"
              />
              <FAB
                data-testid="btn-num-protocolo-processo"
                icon="pencil-alt"
                onClick={() => setShowModalNumeroProtocolo(true)}
                disabled={podeAlterarProtocolo}
                title="Número de protocolo"
              />
              <FAB
                data-testid="btn-num-protocolo-processo"
                icon="clock"
                onClick={async () =>
                  alterarDataPeriodoProcesso(values, setValues)
                }
                disabled={podeAlterarPrazoProcesso}
                title="Data período processo"
              />
              {processo.tipoProcesso?.reuNaoObrigatorio && !processo.reu && (
                <FAB
                  data-testid="btn-add-reu"
                  icon="pencil-alt"
                  onClick={() => setShowModalNovoReu(true)}
                  disabled={podeAlterarPrazoProcesso}
                  title="Vincular Réu ao Processo"
                />
              )}
            </FabSpeedDial>
          </>
        )}
      />
    </Container>
  );
};

const receberProcesso = (processo: Processo, setValues: any) => {
  ProcessoService.receberProcesso(processo.id)
    .then(resp => {
      Alert.success({ title: 'Recebido com sucesso.' });
      setValues({
        ...processo,
        descricaoStatus: 'Aberto'
      });
    })
    .catch(error => {
      Alert.error(
        {
          title: 'Não foi possível receber o processo.'
        },
        error
      );
    });
};

const alterarDataPeriodoProcesso = async (
  processo: Processo,
  setValues: any
) => {
  const response = await getDatesProcesso(
    processo.inicioFiscalizacao,
    processo.fimFiscalizacao
  );
  if (response.value) {
    ProcessoService.alterarPeriodoProcesso(processo.id, {
      periodoInicial: response.value.inicio.value,
      periodoFinal: response.value.fim.value
    })
      .then(resp => {
        Alert.success({ title: 'Alterado com sucesso.' });
        setValues({
          ...processo,
          inicioFiscalizacao: response.value.inicio.value,
          fimFiscalizacao: response.value.fim.value
        });
      })
      .catch(error => {
        Alert.error(
          {
            title: 'Não foi possível alterar o periodo.'
          },
          error
        );
      });
  }
};

const getDatesProcesso = async (inicio?: Date, fim?: Date) => {
  return Alert.question({
    title: 'Deseja alterar o periodo do protocolo ?',
    html: `<div class="form-group">
              <div class="col-md-6">
                <label class="label" for="data-inicio">Inicio Fiscalização</label>
                <input type="date" value=${inicio} id="data-inicio"/>
                </input>
              </div>
              <div class="col-md-6">
                <label class="label" for="data-fim">Fim Fiscalização</label>
                <input type="date" value=${fim} id="data-fim" />
                </input>
              </div>
            </div>`,
    confirmButtonText: 'Aceitar',
    cancelButtonText: 'Cancelar',
    preConfirm: () => {
      return new Promise(resolve => {
        resolve({
          inicio: document.getElementById('data-inicio'),
          fim: document.getElementById('data-fim')
        });
      });
    }
  });
};

const getObservacaoReabrirAto = (): Promise<any> =>
  Alert.question({
    title: 'Deseja reabrir esse ato?',
    input: 'textarea',
    inputPlaceholder: 'Digite o motivo',
    inputValidator: (value: string) =>
      !value ? 'Observação é obrigatória.' : null,
    confirmButtonText: 'Aceitar',
    cancelButtonText: 'Cancelar'
  });

const reabrirProcesso = async (processo: Processo) => {
  const response = await getObservacaoReabrirAto();
  if (response.value) {
    ProcessoService.reabrirProcesso(processo.id, {
      observacao: response.value
    })
      .then(async () => {
        Alert.success({
          title: 'Processo reaberto com sucesso.'
        });
      })
      .catch(error => {
        Alert.error(
          {
            title: 'Não foi possível reabrir o processo.'
          },
          error
        );
      });
  }
};

const ButtonContainer = styled.div`
  display: flex;
  flexdirection: row;
  float: right;
  margin-top: -5px;
`;
